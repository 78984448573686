import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Service from "../utils/networkutils";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Box, Fab, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DropzoneArea } from 'material-ui-dropzone';
// import BalfourBeattyHeader from '../BBHeader/BalfourBeattyHeader';
import BalfourBeattyHeaderNoArrow from '../BBHeader/BalfourBeattyHeaderNoArrow';

const token = localStorage.getItem("token");

var points = []


class RoadMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            open: false,
            selectedData: {},
            open2: false,
        };
        this.onMarkerClick = this.onMarkerClick.bind(this);
    }

    async componentDidMount() {
        // console.log(localStorage.getItem('bb-demo-map-login'))
        if (localStorage.getItem('bb-demo-map-login') !== "true") {
            window.location.href ="/login"
        }
        //demo
        Service.get(process.env.PUBLIC_URL + '/latlng1.json', {
            headers: {
                Authorization: "Token " + token
            }
        })
            .then(res => {
                console.log("MapData\n");
                // console.log(JSON.stringify(res.data.coordinates[0][0][1]));
                this.reFormatJSON(res.data.Sheet1);
                this.setState({
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("error: " + error.code);
            });
    }

    reFormatJSON(data) {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            points.push(element);
        }
        console.log(points)
    }

    fetchPlaces(mapProps, map) {
        const { google } = mapProps;
        let googleBounds = new google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            googleBounds.extend({lat: parseFloat(points[i].lat), lng: parseFloat(points[i].lng)});
        }
        console.log(googleBounds)
        map.fitBounds(googleBounds);
        // ...
    }

    onMarkerClick(val, val1) {
        // console.log(val1)
        this.setState({
            open: true,
            selectedData: val1,
        })
    }

    render() {
        const handleClose = () => {
            this.setState({
                open: false,

            })
        };
        const handleClose2 = () => {
            this.setState({
                open2: false,

            })
        };

        return (
            <div>
                <BalfourBeattyHeaderNoArrow />
                {(this.state.isLoading) ? (
                    <div>Loading.....</div>
                ) : (
                        <Map
                            style={{ height: '93.5%' }}
                            google={this.props.google}
                            onReady={this.fetchPlaces}
                        >
                            {points.map((value, index) => {
                                // console.log(value.lat)
                                return (
                                    <Marker
                                        title={value.title}
                                        onClick={(e) => this.onMarkerClick(e, value)}
                                        name={value.name}
                                        position={{ lat: value.lat, lng: value.lng }} />
                                )
                            })}

                            {/* <Polyline
                        geodesic={true}
                        options={{
                            path: points,
                            strokeColor: '#0000ff',
                            strokeOpacity: 1,
                            strokeWeight: 8,
                            icons: [{
                                offset: '0',
                                repeat: '10px'
                            }],
                        }} /> */}
                        </Map>

                    )}
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.selectedData.title}</DialogTitle>
                    <DialogContent>
                        {/* <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Typography fullWidth><Box fontWeight="fontWeightBold">Enquiry Number :</Box> {this.state.selectedData.enq_num}</Typography>
                        </Box> */}
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Typography fullWidth><Box fontWeight="fontWeightBold">Company   :</Box> {this.state.selectedData.title}</Typography>
                        </Box>
                        {/* <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Typography fullWidth><Box fontWeight="fontWeightBold">Customer       :</Box> {this.state.selectedData.name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Typography fullWidth><Box fontWeight="fontWeightBold">Technique      :</Box> {this.state.selectedData.tech}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Typography fullWidth><Box fontWeight="fontWeightBold">Engineer       :</Box> {this.state.selectedData.eng}</Typography>
                        </Box> */}
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
          </Button>
                    </DialogActions>
                </Dialog>
                {/* <Dialog
                    open={this.state.open2}
                    onClose={handleClose2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Add Project"}</DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <TextField id="bb-data-map-latitude" label="Latitude" type="number" variant="outlined" fullWidth />
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <TextField id="bb-data-map-longitude" label="Longitude" type="number" variant="outlined" fullWidth />
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <TextField id="bb-data-map-project-name" label="Project Name" type="number" variant="outlined" fullWidth />
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <TextField id="bb-data-map-project-detail" label="Details" type="number" variant="outlined" fullWidth />
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <DropzoneArea

                            />
                        </Box>

                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose2} color="primary" autoFocus>
                            Close
          </Button>
                    </DialogActions>
                </Dialog> */}
                {/* <div style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '55px',
                    padding: '20px',
                }}>

                    <Fab color="primary" aria-label="add" onClick={() => { this.setState({ open2: true }) }}>
                        <AddIcon />
                    </Fab>

                </div> */}
            </div>


        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDqNmZ2fMtyJacfs_vCd6k7z2V_36J4nEM')
})(RoadMap);