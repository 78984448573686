import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const balfour = createMuiTheme({
    palette: {
        secondary: {
            main: '#005D99',
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appbarimg: {
        height: 56,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 48,
        },
        [theme.breakpoints.up('sm')]: {
            height: 64,
        },
    },
}));

function BalfourBeattyHeaderNoArrow(props) {
    const classes = useStyles();

    const handleSubmit = e => {
        // e.preventDefault();
        localStorage.setItem('bb-demo-map-login', 'false')
        window.location.reload()
    }

    return (
        <div className={classes.root}>
            <ThemeProvider theme={balfour}>
                <AppBar position="static">
                    <Toolbar edge="start" style={{ backgroundColor: '#ffffff' }}>
                        <img src={process.env.PUBLIC_URL + "/bb/BB_RGB_5.5mm.png"} alt="Balfour Beatty" className={classes.appbarimg} />
                        <Typography variant="h6" className={classes.title}>
                            
          </Typography>
                        <Button color="primary" onClick={handleSubmit}>Logout</Button>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </div >
    );
}

export default withRouter(BalfourBeattyHeaderNoArrow) 