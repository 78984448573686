import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import DemoMap from './DemoMap/DemoMap';
import Login from './Login/Login';

function App() {
  return (
    <BrowserRouter >

      <Switch>
        <Route exact path="/" component={DemoMap} />
        <Route exact path="/login" component={Login} />

      </Switch>

    </BrowserRouter>
  );
}

export default App;
